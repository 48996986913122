import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LayoutSessionComponent } from './shared/layouts/layout-session/layout-session.component';
import { SessionGuard } from './guards/session-auth.guard';
import { environment } from '@env/environment';
import { HasPlanGuard } from './guards/has-plan.guard';

const routes: Routes = [
	{
		path: 'painel',
		loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
		canLoad: [SessionGuard, HasPlanGuard]
	},
	{
		component: LayoutSessionComponent,
		matcher: (url: UrlSegment[]) => {
			const path = url[0]?.path;

			if (!path) {
				return null;
			}

			if (
				path.startsWith('login') ||
				path.startsWith('forgot-password') ||
				path.startsWith('logout') ||
				path.startsWith('choose-plan')
			) {
				return { consumed: [] };
			}
			return null;
		},
		children: [
			{
				path: '',
				loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule)
			},
			{
				path: 'choose-plan',
				loadChildren: () => import('./views/private/plan/plan.module').then(m => m.PlanModule),
				canLoad: [SessionGuard]
			}
		]
	},
	{
		path: '',
		loadChildren: () => import('./views/marketplace/marketplace.module').then(m => m.MarketplaceModule),
		data: {
			breadcrumb: 'Home'
		}
	},
  {
    path: 'onboarding',
    loadChildren: () => import('./views/onboarding/onboarding.module').then(m => m.OnboardingModule),
  },
  {
    path: 'telemedicina',
    loadChildren: () => import('./views/telemedicine/telemedicine.module').then(m => m.TelemedicineModule),
  },
	{ path: 'history', loadChildren: () => import('./views/private/history/history.module').then(m => m.HistoryModule) },
	{
		path: '**',
		redirectTo: environment.home
	}
];

@NgModule({
	imports: [
    RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
			scrollPositionRestoration: 'enabled',
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
